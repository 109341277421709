<template>
  <div v-if="!loadingProductDetail">
    <app-breadcrumb
      title="Detail Produk"
      hide-breadcrumbs
    />
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="3"
      >
        <v-card>
          <div class="pa-4 pb-2">
            <span class="text-subtitle-2 font-weight-bold d-block primary--text">{{ productDetail.name }}</span>
            <span class="text-caption font-medium">{{ productDetail.description }}</span>
          </div>
          <div class="px-4 py-2">
            <div
              v-for="i in 6"
              :key="i"
              class="d-flex align-center mb-4"
            >
              <v-icon
                size="32px"
                class="mr-2"
              >
                {{ icons.mdiInformation }}
              </v-icon>
              <div class="d-flex flex-column">
                <span class="text-caption">Informasi Tambahan {{ i }}</span>
                <span>Informasi Tambahan {{ i }}</span>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import { mdiInformation } from '@mdi/js'
import AppBreadcrumb from '@/components/misc/AppBreadcrumb.vue'
import useProduct from '@/composables/useProduct'

export default {
  components: {
    AppBreadcrumb,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
  },
  setup(props) {
    const {
      productDetail, loadingProductDetail, getProductDetail,
    } = useProduct()

    onMounted(() => {
      getProductDetail(+props.id)
    })

    return {
      productDetail,
      loadingProductDetail,

      getProductDetail,

      icons: {
        mdiInformation,
      },
    }
  },
}
</script>

<style></style>
